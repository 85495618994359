define("docyet-client/components/base-components/carousel", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if hasBlock}}
    {{yield fixedItems}}
  {{/if}}
  */
  {
    "id": "e6XuHLFJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[35,0]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fixedItems\",\"if\"]}",
    "moduleName": "docyet-client/components/base-components/carousel.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend(Ember.Evented, {
    accessibility: true,
    adaptiveHeight: false,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
    asNavFor: null,
    centerMode: false,
    centerPadding: '50px',
    cssEase: 'ease',
    dots: false,
    draggable: true,
    fade: false,
    focusOnSelect: false,
    easing: 'linear',
    edgeFriction: 0.15,
    infinite: true,
    initialSlide: 0,
    mobileFirst: false,
    pauseOnHover: true,
    pauseOnDotsHover: false,
    respondTo: 'window',
    responsive: null,
    rows: 1,
    slide: '',
    slidesPerRow: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,
    swipe: true,
    swipeToSlide: false,
    touchMove: true,
    touchThreshold: 5,
    useCSS: true,
    variableWidth: false,
    vertical: false,
    verticalSwiping: false,
    rtl: false,
    items: null,
    lazyLoad: 'ondemand',
    currentSlide: null,
    fixedItems: null,
    // Items of carousel component are fixed since the update of this attributes 
    // breaks the carousel. Carousel keeps more robust.
    init: function init() {
      this._super.apply(this, arguments);

      this.set("fixedItems", this.items);
    },

    /* Addon initializers */
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().slick({
        accessibility: this.accessibility,
        adaptiveHeight: this.adaptiveHeight,
        autoplay: this.autoplay,
        autoplaySpeed: this.autoplaySpeed,
        arrows: this.arrows,
        asNavFor: this.asNavFor,
        prevArrow: (0, _jquery.default)('.prev'),
        nextArrow: (0, _jquery.default)('.next'),
        centerMode: this.centerMode,
        centerPadding: this.centerPadding,
        cssEase: this.cssEase,
        customPaging: this.customPaging,
        dots: this.dots,
        draggable: this.draggable,
        fade: this.fade,
        focusOnSelect: this.focusOnSelect,
        easing: this.easing,
        edgeFriction: this.edgeFriction,
        infinite: this.infinite,
        initialSlide: this.initialSlide,
        lazyLoad: this.lazyLoad,
        mobileFirst: this.mobileFirst,
        pauseOnHover: this.pauseOnHover,
        pauseOnDotsHover: this.pauseOnDotsHover,
        respondTo: this.respondTo,
        responsive: this.responsive,
        rows: this.rows,
        slide: this.slide,
        slidesPerRow: this.slidesPerRow,
        slidesToShow: this.slidesToShow,
        slidesToScroll: this.slidesToScroll,
        speed: this.speed,
        swipe: this.swipe,
        swipeToSlide: this.swipeToSlide,
        touchMove: this.touchMove,
        touchThreshold: this.touchThreshold,
        useCSS: this.useCSS,
        variableWidth: this.variableWidth,
        vertical: this.vertical,
        verticalSwiping: this.verticalSwiping,
        rtl: this.rtl
      }); // Needs refactor to this.element.addEventlistener, makes first doctor card unable to close until swiped once

      this.$().on('afterChange', function (event, slick, currentSlide) {
        Ember.set(_this, 'currentSlide', currentSlide);
      }); // makes correction on the first slide that appears cut in some cards

      if (this.adaptiveHeight) setTimeout(function () {
        return (0, _jquery.default)(".slick-list").css("height", (0, _jquery.default)(".slick-current").outerHeight());
      }, 50);
    },
    // Needs replacement for jquery
    slideObserver: Ember.observer('currentSlide', function () {
      if (this.currentSlide) {
        this.$().slick('slickGoTo', this.currentSlide);
      }
    })
  }));

  _exports.default = _default;
});