define("docyet-client/initializers/component-router-injector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    // Injects all Ember components with a router object:
    application.inject('component', 'router', 'router:main');
  }
  /**
   * Provides component route injector when APP loads
   */


  var _default = {
    name: 'component-router-injector',
    initialize: initialize
  };
  _exports.default = _default;
});