define("docyet-client/session-stores/application", ["exports", "ember-simple-auth/session-stores/local-storage"], function (_exports, _localStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _localStorage.default.extend({
    key: "".concat(window.location.host, "-token")
  });

  _exports.default = _default;
});