define("docyet-client/components/base-components/revealer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if revealing}}
    {{yield (hash revealed="base-components/blank")}}
  {{else}}
    {{yield (hash no-revealed="base-components/blank")}}
  {{/if}}
  */
  {
    "id": "UG+eZhFi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,0],null,[[\"revealed\"],[\"base-components/blank\"]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,[[30,[36,0],null,[[\"no-revealed\"],[\"base-components/blank\"]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"revealing\",\"if\"]}",
    "moduleName": "docyet-client/components/base-components/revealer.hbs"
  });

  /**
   * Reveals/hides elements with custom animations.
   */
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    animationManager: Ember.inject.service(),
    revealing: false,
    // Input Properties
    // ----------------
    isRevealed: false,
    // Auxiliar Functions
    // ------------------

    /**
     * Sometimes the input flag has null value. To be sure all possible
     * cases are covered the function makes a intensive check.
     * @returns {boolean|*}
     */
    canToggle: function canToggle() {
      return !!this.isRevealed !== !!this.revealing;
    },

    /**
     * Hide/Reveal component logic.
     */
    toggle: function toggle() {
      var _this = this;

      if (this.canToggle()) {
        return this.animationManager.defaultRevealerOutAnimation(this.element).finished.then(function () {
          // Secures rendering problems
          if (!(_this.isDestroyed || _this.isDestroying)) {
            _this.set('revealing', _this.isRevealed);

            return _this.animationManager.defaultRevealerInAnimation(_this.element);
          }
        });
      }
    },
    // Component Hooks
    // ---------------
    didInsertElement: function didInsertElement() {
      this.toggle();
    },
    // Observers
    // ---------

    /**
     * Observes isRevealed flag to show/hide revealers.
     * Logic to hide/reveal goes here.
     */
    toggleReveal: Ember.observer('isRevealed', function () {
      this.toggle();
    })
  }));

  _exports.default = _default;
});