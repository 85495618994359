define("docyet-client/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TDTYEyMt",
    "block": "{\"symbols\":[],\"statements\":[[8,\"welcome-wrapper\",[],[[\"@showLogo\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"base-login/login\",[],[[\"@login\"],[[32,0,[\"login\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "docyet-client/templates/login.hbs"
  });

  _exports.default = _default;
});