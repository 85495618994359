define("docyet-client/components/base-components/progress-arc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  */
  {
    "id": "cm6UDtJT",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "docyet-client/components/base-components/progress-arc.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    _polarToCartesian: function _polarToCartesian(centerX, centerY, radius, angle) {
      var angleInRadians = (angle - 90) * Math.PI / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
      };
    },
    _generate_arc: function _generate_arc(x, y, radius, startAngle, endAngle) {
      var start = this._polarToCartesian(x, y, radius, endAngle);

      var end = this._polarToCartesian(x, y, radius, startAngle);

      var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
      var d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ");
      return d;
    },
    actions: {
      toggleSidebar: function toggleSidebar() {
        this.toggleSidebar();
      },
      logout: function logout() {
        this.toggleSidebar();
        this.logout();
      }
    }
  }));

  _exports.default = _default;
});