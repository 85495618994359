define("docyet-client/components/base-components/navbar-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "flhBuDfj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "docyet-client/components/base-components/navbar-page.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    // Component Input Properties
    // --------------------------
    icon: false,
    iconPath: 'assets/images/profile/avatar.svg'
  }));

  _exports.default = _default;
});