define("docyet-client/templates/non-valid-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DRn4hZ2J",
    "block": "{\"symbols\":[],\"statements\":[[8,\"base-application/non-valid-token\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "docyet-client/templates/non-valid-token.hbs"
  });

  _exports.default = _default;
});