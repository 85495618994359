define("docyet-client/templates/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O3oS/S1O",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"base-configuration/menu\",[[16,0,[34,2]]],[[\"@user\",\"@updateAccountCredentials\",\"@deleteAccount\"],[[34,0],[30,[36,1],[[32,0],[32,0,[\"updateAccountCredentials\"]]],null],[30,[36,1],[[32,0],[32,0,[\"deleteAccount\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"user\",\"action\",\"styleNamespace\",\"if\"]}",
    "moduleName": "docyet-client/templates/configuration.hbs"
  });

  _exports.default = _default;
});