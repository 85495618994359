define("docyet-client/templates/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CPp5lAzf",
    "block": "{\"symbols\":[],\"statements\":[[8,\"base-components/navbar-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"base-help/menu\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "docyet-client/templates/help.hbs"
  });

  _exports.default = _default;
});