define("docyet-client/helpers/percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.percentage = percentage;

  function percentage(pct, opts) {
    opts = opts || {};
    var value = (pct || 0) * 100;
    var numDecimals = opts.decimals || 0;
    return value > 0 ? "".concat(value.toFixed(value % 1 === 0 ? 0 : numDecimals), "%") : '';
  }

  var _default = Ember.Helper.helper(percentage);

  _exports.default = _default;
});