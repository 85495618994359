define("docyet-client/initializers/connection-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'connectionStatus', 'service:connection-status');
  }
  /**
   * Provides connection status service when APP loads
   */


  var _default = {
    name: 'connection-status',
    initialize: initialize
  };
  _exports.default = _default;
});