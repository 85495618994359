define("docyet-client/templates/signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OyO9n1ek",
    "block": "{\"symbols\":[],\"statements\":[[8,\"welcome-wrapper\",[],[[\"@showLogo\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"base-signup/sign-up\",[],[[\"@signup\",\"@checkEmailAvailability\"],[[30,[36,0],[[32,0],[32,0,[\"signup\"]]],null],[30,[36,0],[[32,0],[32,0,[\"checkEmailAvailability\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "docyet-client/templates/signup.hbs"
  });

  _exports.default = _default;
});