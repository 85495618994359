define("docyet-client/helpers/form-collection-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formcollectionItem = formcollectionItem;

  /**
   * Given a Form Collection and a value returns its corresponding label
   * @param params - first parameter list, second index of item to extract
   * @returns {*} - item from list
   */
  function formcollectionItem(params
  /* , hash */
  ) {
    var collection = params[0];
    var value = params[1];
    var result = collection.find(function (e) {
      return e.value === value;
    });
    return result ? result.label : value;
  }

  var _default = Ember.Helper.helper(formcollectionItem);

  _exports.default = _default;
});