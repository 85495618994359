define("docyet-client/templates/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z+A7wC+v",
    "block": "{\"symbols\":[],\"statements\":[[8,\"base-settings/menu\",[],[[\"@userName\",\"@logout\",\"@returnRoute\"],[[34,0],[30,[36,1],[[32,0],[32,0,[\"logout\"]]],null],[30,[36,1],[[32,0],[32,0,[\"returnRoute\"]]],null]]],null]],\"hasEval\":false,\"upvars\":[\"userName\",\"action\"]}",
    "moduleName": "docyet-client/templates/menu.hbs"
  });

  _exports.default = _default;
});