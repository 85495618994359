define("docyet-client/helpers/filter-hidden-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.filterHiddenFields = filterHiddenFields;

  function filterHiddenFields(params) {
    // THIS IS JUST A FAST WORKAROUND SINCE CLIENT WILL BE DEPRECATED SOON
    var hiddenFieldsArray = ['frpa.schellong', 'frpa.moca', 'frpa.cfs', 'frpa.csu', 'frpa.gait', "frpa.laydown_test", "frpa.standing_test", "frpa.one_min_test", "frpa.three_min_test", "frpa.phq4_interpretation"];
    return hiddenFieldsArray.includes(params[0]) ? false : true;
  }

  var _default = Ember.Helper.helper(filterHiddenFields);

  _exports.default = _default;
});