define("docyet-client/components/base-chatbot/messages/cards/diagnosis/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="card__navbar">
    {{yield navbar}}
  </div>
  
  <div class="card__header">
    {{yield header}}
  </div>
  
  <div class="card__subheader">
    {{yield subheader}}
  </div>
  
  <div class="card__body">
    {{yield body}}
  </div>
  
  {{#unless this.isHistory}}
    <div class="card__footer">
      {{yield footer}}
    </div>
  {{/unless}}
  
  */
  {
    "id": "I/c0vIyo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"card__navbar\"],[12],[2,\"\\n  \"],[18,1,[[35,1]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card__header\"],[12],[2,\"\\n  \"],[18,1,[[35,2]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card__subheader\"],[12],[2,\"\\n  \"],[18,1,[[35,3]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card__body\"],[12],[2,\"\\n  \"],[18,1,[[35,4]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,5],[[32,0,[\"isHistory\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"card__footer\"],[12],[2,\"\\n    \"],[18,1,[[35,0]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"footer\",\"navbar\",\"header\",\"subheader\",\"body\",\"unless\"]}",
    "moduleName": "docyet-client/components/base-chatbot/messages/cards/diagnosis/card.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    init: function init() {
      this.set('navbar', {
        isNavbar: true
      });
      this.set('header', {
        isHeader: true
      });
      this.set('subheader', {
        isSubheader: true
      });
      this.set('body', {
        isBody: true
      });
      this.set('footer', {
        isFooter: true
      });

      this._super.apply(this, arguments);
    }
  }));

  _exports.default = _default;
});