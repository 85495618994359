define("docyet-client/helpers/translate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  /**
   * This helper allows to translate locally a part of the UI,
   * independently of which language is set, via an additional
   * parameter following the text to translate
   */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    onLocalesInit: Ember.observer('intl.locales', function () {
      this.recompute();
    }),
    compute: function compute(params) {
      var _this = this;

      var value = params[0];
      var sectionName = transformSectionName(params[1]); // translate if it exists

      if (this.intl.exists(value)) return this.intl.t(value); // otherwise tries to find a match

      switch (_typeof(value)) {
        case 'object':
          return value.map(function (val) {
            return {
              value: val.value,
              label: matchValue(val.label, sectionName, _this.intl)
            };
          });

        case 'string':
          return matchValue(value, sectionName, this.intl);

        default:
          return value;
      }
    }
  }); // get section name to help translate values


  _exports.default = _default;

  var transformSectionName = function transformSectionName(name) {
    name = name.split('.');
    name.pop();
    return name.join('.');
  }; // check if its an amalgamation of translatable values


  var matchValue = function matchValue(value, sectionName, intl) {
    if (value.match(/&(.*?)&/g)) {
      var matches = value.match(/&(.*?)&/g);
      return matches.map(function (match) {
        match = match.replaceAll('&', '');
        return intl.t("".concat(sectionName, ".").concat(match));
      }).join(",");
    } else return value;
  };
});