define("docyet-client/components/base-chatbot/messages/cards/form-wrapper", ["exports", "animejs"], function (_exports, _animejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <i class="fa fa-times docyet-h4"
     onclick = {{action "closeModal"}}></i>
  
  <div class="docyet-card-layout">
    <div>
      <h1>form name</h1>
  
      <div>
        {{#if (not answered)}}
          {{#if editing}}
            <span onclick={{action "disableEdition"}} class="icon">
            <i class="fa fa-eraser"></i>
          </span>
          {{else}}
            <span onclick={{action "enableEdition"}} class="icon">
            <i class="fa fa-pencil"></i>
          </span>
          {{/if}}
        {{/if}}
      </div>
    </div>
  
    {{#if (and editing (not answered))}}
      <BaseChatbot::Messages::Cards::Form::FormInput 
        @form={{form}} 
        @afterSubmitForm={{action "disableEdition"}} />
    {{else}}
      <BaseChatbot::Messages::Cards::Form::FormDetails 
        @form={{form}} 
        @answered={{answered}} 
        @hideModal={{hideModal}} 
        @submitForm={{submitForm}} />
    {{/if}}
  </div>
  */
  {
    "id": "1K1DjXpw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[14,0,\"fa fa-times docyet-h4\"],[15,\"onclick\",[30,[36,4],[[32,0],\"closeModal\"],null]],[14,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"docyet-card-layout\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"form name\"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,7],[[35,1]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[15,\"onclick\",[30,[36,4],[[32,0],\"disableEdition\"],null]],[14,0,\"icon\"],[14,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-eraser\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"span\"],[15,\"onclick\",[30,[36,4],[[32,0],\"enableEdition\"],null]],[14,0,\"icon\"],[14,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,8],[[35,5],[30,[36,7],[[35,1]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"base-chatbot/messages/cards/form/form-input\",[],[[\"@form\",\"@afterSubmitForm\"],[[34,0],[30,[36,4],[[32,0],\"disableEdition\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"base-chatbot/messages/cards/form/form-details\",[],[[\"@form\",\"@answered\",\"@hideModal\",\"@submitForm\"],[[34,0],[34,1],[34,2],[34,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"form\",\"answered\",\"hideModal\",\"submitForm\",\"action\",\"editing\",\"if\",\"not\",\"and\"]}",
    "moduleName": "docyet-client/components/base-chatbot/messages/cards/form-wrapper.hbs"
  });

  /**
   * Form Details component: detailed view of form fields, with toggle
   * option to switch between view and edit views
   */
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    // Component Input Properties
    editing: false,
    hideModal: null,
    form: null,
    answered: null,
    submitForm: null,
    actions: {
      closeModal: function closeModal() {
        this.hideModal();
      },
      enableEdition: function enableEdition() {
        var _this = this;

        (0, _animejs.default)({
          targets: '.docyet-form-details-section',
          translateX: '-100%',
          easing: 'easeInQuad',
          duration: 600
        });
        (0, _animejs.default)({
          targets: '.docyet-form-details-section',
          opacity: 0,
          easing: 'easeInQuad',
          duration: 400,
          delay: 200
        });
        setTimeout(function () {
          _this.set('editing', true);
        }, 700);
      },
      disableEdition: function disableEdition() {
        var _this2 = this;

        (0, _animejs.default)({
          targets: '.docyet-form-input-section',
          translateX: '-100%',
          easing: 'easeInQuad',
          duration: 600
        });
        (0, _animejs.default)({
          targets: '.docyet-form-input-section',
          opacity: 0,
          easing: 'easeInQuad',
          duration: 400,
          delay: 200
        });
        setTimeout(function () {
          _this2.set('editing', false);
        }, 700);
      }
    }
  }));

  _exports.default = _default;
});