define("docyet-client/templates/terms-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dZUN0VM4",
    "block": "{\"symbols\":[],\"statements\":[[8,\"base-application/terms-conditions\",[],[[\"@submitSignupForm\",\"@submitAokSignupForm\"],[[30,[36,0],[[32,0],\"login\"],null],[30,[36,0],[[32,0],\"aokLogin\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "docyet-client/templates/terms-conditions.hbs"
  });

  _exports.default = _default;
});