define("docyet-client/router", ["exports", "docyet-client/config/environment", "ember-previous/mixins/previous"], function (_exports, _environment, _previous) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend(_previous.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  /**
   * APP route definition
   */

  Router.map(function () {
    if (_environment.default.liveLoginEnabled || _environment.default.application !== 'default') {
      this.route('live-login', {
        path: '/'
      });
      this.route('live-login');
    }

    this.route('chatbot');
    this.route('login');
    this.route('signup');
    this.route('profile');
    this.route('configuration');
    this.route('legal');
    this.route('doctors');
    this.route('feature-discovery');
    this.route('reset-password');
    this.route('help');
    this.route('menu');
    this.route('history');
    this.route('terms-conditions');
    this.route('non-valid-token');
    return null;
  });
  var _default = Router;
  _exports.default = _default;
});