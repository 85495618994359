define("docyet-client/helpers/html-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.htmlString = htmlString;

  /**
   * Returns a string that safely injects html tags
   * @param params - first parameter string with html tags
   * @returns {*} - htmlSafe string
   */
  function htmlString(params
  /* , hash */
  ) {
    var string = params[0];
    return Ember.String.htmlSafe(string);
  }

  var _default = Ember.Helper.helper(htmlString);

  _exports.default = _default;
});