define("docyet-client/helpers/form-field-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formFieldErrors = formFieldErrors;

  function formFieldErrors(params
  /* , hash */
  ) {
    var errors = params[0];
    var field = params[1];
    var errorMessages = [];
    if (!errors || Object.keys(errors).length < 1) return [];

    if (Object.keys(errors).includes(field.label)) {
      errorMessages.push(errors[field.label].message);
    }

    return errorMessages;
  }

  var _default = Ember.Helper.helper(formFieldErrors);

  _exports.default = _default;
});