define("docyet-client/templates/legal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iU9i8i5S",
    "block": "{\"symbols\":[],\"statements\":[[8,\"base-components/navbar-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"base-legal/legal\",[],[[\"@returnRoute\",\"@getPrivacyLevel\",\"@updatePrivacyLevel\",\"@deleteMessages\",\"@deleteReports\",\"@updateDeleteFlags\",\"@canDeleteMessages\",\"@canDeleteReports\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5],[34,6],[34,7]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"returnRoute\",\"getPrivacyLevel\",\"updatePrivacyLevel\",\"deleteMessages\",\"deleteReports\",\"updateDeleteFlags\",\"canDeleteMessages\",\"canDeleteReports\"]}",
    "moduleName": "docyet-client/templates/legal.hbs"
  });

  _exports.default = _default;
});