define("docyet-client/components/base-chatbot/messages/replies/wait-agent-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p>{{t "chatbot.waiting-agent"}}</p>
  */
  {
    "id": "lQcrDfod",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[1,[30,[36,0],[\"chatbot.waiting-agent\"],null]],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "docyet-client/components/base-chatbot/messages/replies/wait-agent-reply.hbs"
  });

  /**
   * Displays card carousel
   */
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    animationManager: Ember.inject.service(),

    /**
     * After new message is inserted scroll down message list
     */
    didInsertElement: function didInsertElement() {
      this.animationManager.recommendationInAnimation(this.element);
    }
  }));

  _exports.default = _default;
});