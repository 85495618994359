define("docyet-client/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Me28W3r6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"welcome-wrapper\",[],[[\"@showLogo\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"base-reset-password/reset-password\",[],[[\"@resetPassword\"],[[30,[36,0],[[32,0],[32,0,[\"resetPassword\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"base-reset-password/request-reset-password\",[],[[\"@requestResetPassword\"],[[30,[36,0],[[32,0],[32,0,[\"requestResetPassword\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"token\",\"if\"]}",
    "moduleName": "docyet-client/templates/reset-password.hbs"
  });

  _exports.default = _default;
});