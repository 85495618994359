define("docyet-client/components/base-chatbot/messages/replies/alert-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="docyet-chat-button docyet-text" {{action "displayAlert"}}>
    {{alert.label}}
  </div>
  */
  {
    "id": "8tzE1jt8",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"docyet-chat-button docyet-text\"],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[4,[38,0],[[32,0],\"displayAlert\"],null],[12],[2,\"\\n  \"],[1,[35,1,[\"label\"]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"alert\"]}",
    "moduleName": "docyet-client/components/base-chatbot/messages/replies/alert-reply.hbs"
  });

  /**
   * Location Quick Reply component: quick option designed specifically for sharing
   * location via message
   */
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    animationManager: Ember.inject.service(),
    // Input Properties
    // ----------------
    alert: null,
    displayAlert: null,
    // Component Hooks
    // ---------------

    /**
     * After new message is inserted scroll down message list
     */
    didInsertElement: function didInsertElement() {
      this.animationManager.messageInAnimation(this.element.children);
    },
    actions: {
      displayAlert: function displayAlert() {
        this.displayAlert(this.alert);
        this.animationManager.quickReplyOutAnimation(this.element.children);
      }
    }
  }));

  _exports.default = _default;
});