define("docyet-client/helpers/includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.includes = includes;

  function includes(params) {
    return params[0] ? params[0].includes(params[1]) : false;
  }

  var _default = Ember.Helper.helper(includes);

  _exports.default = _default;
});