define("docyet-client/templates/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SA3Wd6+/",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,10],[[30,[36,9],[[35,0],[35,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"base-profile/profile\",[],[[\"@user\",\"@profile\",\"@deleteProfile\",\"@updateProfile\",\"@exportMedicalProfile\",\"@updateAccountCredentials\",\"@deleteAccount\",\"@connectThryve\",\"@syncThryve\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5],[34,6],[34,7],[34,8]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"user\",\"profile\",\"deleteProfile\",\"updateProfile\",\"exportMedicalProfile\",\"updateAccountCredentials\",\"deleteAccount\",\"connectThryve\",\"syncThryve\",\"and\",\"if\"]}",
    "moduleName": "docyet-client/templates/profile.hbs"
  });

  _exports.default = _default;
});