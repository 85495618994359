define("docyet-client/components/base-components/application-revealer", ["exports", "docyet-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if isEmbedded}}
    <BaseComponents::Revealer @isRevealed={{showingApp}} as |revealer|>
      <BaseComponents::RevealerState @state={{revealer.revealed}}>
        <div>{{yield}}</div>
      </BaseComponents::RevealerState>
  
      <BaseComponents::RevealerState @state={{revealer.no-revealed}}>
        <div></div>
      </BaseComponents::RevealerState>
    </BaseComponents::Revealer>
  
    <p class="docyet-embedded-button {{if showingApp "docyet-primary" ""}}" {{action "toggleAppDisplay"}}>{{t "chatbot.corona-information"}}</p>
  {{else}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "fOfznXSY",
    "block": "{\"symbols\":[\"revealer\",\"&default\"],\"statements\":[[6,[37,1],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"base-components/revealer\",[],[[\"@isRevealed\"],[[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"base-components/revealer-state\",[],[[\"@state\"],[[32,1,[\"revealed\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[12],[18,2,null],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"base-components/revealer-state\",[],[[\"@state\"],[[32,1,[\"no-revealed\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[11,\"p\"],[16,0,[31,[\"docyet-embedded-button \",[30,[36,1],[[35,0],\"docyet-primary\",\"\"],null]]]],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[4,[38,2],[[32,0],\"toggleAppDisplay\"],null],[12],[1,[30,[36,3],[\"chatbot.corona-information\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"showingApp\",\"if\",\"action\",\"t\",\"isEmbedded\"]}",
    "moduleName": "docyet-client/components/base-components/application-revealer.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    isEmbedded: _environment.default.embedded,
    showingApp: true,
    actions: {
      toggleAppDisplay: function toggleAppDisplay() {
        this.set('showingApp', !this.showingApp);
      }
    }
  }));

  _exports.default = _default;
});