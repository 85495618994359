define("docyet-client/serializers/message", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    // This makes that user requests are now with a payload with root key 'user'
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return Ember.String.dasherize(modelName);
    },
    keyForAttribute: function keyForAttribute(key, relationship) {
      if ((key === 'report' || key === 'collection') && relationship !== 'deserialize') return "".concat(Ember.String.underscore(key));
      return key;
    },
    attrs: {
      report: {
        embedded: 'always'
      },
      collection: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});