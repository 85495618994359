define("docyet-client/instance-initializers/namespace-component-injector", ["exports", "ember-component-css/pod-names"], function (_exports, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Injects ember-component-css styleNamespace property in all Glimmer
   * components, so component style works for Octane updates.
   * To make use of the styles you still have to wrap the Glimmer component
   * with a div with class={{styleNamespace}}.
   */

  /**
   * Given a component path this function extracts the factory class from the APP
   * instance. This way we can use the class later on to inject the styleNamespace
   * property.
   *
   * @param {Object} appInstance
   * @param {string} componentPath
   */
  function extractComponentFactoryClass(appInstance, componentPath) {
    var factoryForComponent = appInstance.factoryFor("component:".concat(componentPath));
    if (!factoryForComponent) return null;
    return factoryForComponent.class;
  }
  /**
   * Given a Component Factory class this function adds a new field "styleNamespace" to
   * it so all component instances include this property. This styleNamespace is the one
   * generated by Ember Component CSS for the component and can be used as a class to
   * inject the component style in the service:
   *
   * // component hbs
   * <div class={{styleNamespace}}>
   *   ...
   * </div>
   *
   * This is something that you will have to do in all Glimmer extended components to make
   * them work with ember-component-css.
   *
   * @param {class} componentFactoryClass
   * @param {string} styleNamespace
   */


  function addStyleNameSpaceToComponentFactoryClass(componentFactoryClass, styleNamespace) {
    if (!componentFactoryClass) return null;
    Object.defineProperty(componentFactoryClass.prototype, "styleNamespace", {
      configurable: true,
      enumerable: true,
      get: function get() {
        return styleNamespace;
      }
    });
  }
  /**
   * Given a Component Factory class this function adds a new field "componentPath" to
   * it so all component instances can infer their respective paths.
   *
   * @param componentFactoryClass
   * @param componentPath
   */


  function addNameSpaceToComponentFactoryClass(componentFactoryClass, componentPath) {
    if (!componentFactoryClass) return null;
    Object.defineProperty(componentFactoryClass.prototype, "componentPath", {
      configurable: true,
      enumerable: true,
      get: function get() {
        return componentPath;
      }
    });
  }

  function initialize(appInstance) {
    for (var _i = 0, _Object$entries = Object.entries(_podNames.default); _i < _Object$entries.length; _i++) {
      var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          componentPath = _Object$entries$_i[0],
          styleNamespace = _Object$entries$_i[1];

      var componentConstructor = extractComponentFactoryClass(appInstance, componentPath);
      addNameSpaceToComponentFactoryClass(componentConstructor, componentPath);
      addStyleNameSpaceToComponentFactoryClass(componentConstructor, styleNamespace);
    }
  }

  var _default = {
    after: "route-styles",
    // run after `ember-component-css` "route-styles"-Initializer
    initialize: initialize
  };
  _exports.default = _default;
});