define("docyet-client/instance-initializers/session-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadSessionEventListeners = loadSessionEventListeners;

  /**
   * This instance initializer is used to keep track of session events,
   * such as login and logout. You can read more information about it in
   * here: https://ember-simple-auth.com/api/classes/ApplicationRouteMixin.html
   * @param instance
   */
  function loadSessionEventListeners(appInstance) {
    var applicationRoute = appInstance.lookup('route:application');
    var session = appInstance.lookup('service:session');
    /**
     * Function triggered whenever user is logged in. Initializes current user service
     * with the current user loaded. If user is not loaded invalidates the session
     */

    session.on('authenticationSucceeded', function () {
      applicationRoute.controllerFor('application').reloadSession();
    });
    /**
     * Function triggered whenever user invalidates session.
     */

    session.on('invalidationSucceeded', function () {
      applicationRoute.controllerFor('application').turnOffApp();
    });
  }

  var _default = {
    name: 'session-events',
    after: 'ember-simple-auth',
    initialize: function initialize(appInstance) {
      loadSessionEventListeners(appInstance);
    }
  };
  _exports.default = _default;
});