define("docyet-client/transforms/raw", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Raw Attribute, pase JSON data as JSON when loading data from server
   * TODO: I think this can be removed
   */
  var _default = _transform.default.extend({
    /**
     * Parse raw data from server to APP
     * @param serialized
     * @returns {*}
     */
    deserialize: function deserialize(serialized) {
      return serialized;
    },

    /**
     * Parse raw attribute from APP to server
     * @param deserialized
     * @returns {*}
     */
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });

  _exports.default = _default;
});