define("docyet-client/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p07IujXE",
    "block": "{\"symbols\":[],\"statements\":[[8,\"welcome-wrapper\",[],[[\"@showLogo\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"base-live-login/login\",[],[[\"@login\"],[[30,[36,0],[[32,0],\"login\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "docyet-client/templates/index.hbs"
  });

  _exports.default = _default;
});