define("docyet-client/components/base-chatbot/messages/replies/agent-chat-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- TODO: add medicalbotclienttranslations --}}
  <p>{{agentName}} {{t "chatbot.agent-joined"}}</p>
  */
  {
    "id": "pbR7wzWF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[1,[34,0]],[2,\" \"],[1,[30,[36,1],[\"chatbot.agent-joined\"],null]],[13]],\"hasEval\":false,\"upvars\":[\"agentName\",\"t\"]}",
    "moduleName": "docyet-client/components/base-chatbot/messages/replies/agent-chat-reply.hbs"
  });

  /**
   * Displays card carousel
   */
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    animationManager: Ember.inject.service(),
    // Computed Input Properties
    // =========================
    agentName: null,

    /**
     * After new message is inserted scroll down message list
     */
    didInsertElement: function didInsertElement() {
      this.animationManager.recommendationInAnimation(this.element);
    }
  }));

  _exports.default = _default;
});