define("docyet-client/components/base-components/blank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "jdR0lt45",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "docyet-client/components/base-components/blank.hbs"
  });

  /**
   * Blank template. Useful for templates with multiple
   * yields, such as the revealer components.
   */
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: ''
  }));

  _exports.default = _default;
});