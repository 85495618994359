define("docyet-client/instance-initializers/i18n", ["exports", "docyet-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Determines device configuration language, and configure i18n service with it
   * @returns {string}
   */
  function calculateLocale(callback) {
    // whatever you do to pick a locale for the user:
    var app = document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;

    if (app) {
      navigator.globalization.getPreferredLanguage(function (language) {
        callback(language.value);
      }, function () {});
    } else {
      callback(navigator.languages[0] || navigator.language);
    }
  }
  /**
   * I18n Initializer, loads i18n configuration before running APP
   */


  var _default = {
    name: 'i18n',
    initialize: function initialize(appInstance) {
      var i18n = appInstance.lookup('service:intl');
      var moment = appInstance.lookup('service:moment');
      calculateLocale(function () {
        // TODO: add language as argument in future
        // let languageCode = language.substr(0, 2).toUpperCase();
        var languageCode = _environment.default.defaultLanguage;

        if (languageCode === 'DE' || languageCode === 'EN') {
          i18n.setLocale(languageCode);
          moment.setLocale(languageCode.toLowerCase());
        } else {
          i18n.setLocale('EN');
          moment.setLocale('en');
        }
      });
    }
  };
  _exports.default = _default;
});