define("docyet-client/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;

  function formatDate(params) {
    return moment(params[0]).format("DD.MM.YYYY");
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});