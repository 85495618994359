define("docyet-client/helpers/is-translatable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper checks whether a string can be translated
   * or not (if there are available medicalbotclienttranslations for it)
   */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      return this.intl.exists(params[0], this.get('intl.locale')[0]);
    }
  });

  _exports.default = _default;
});