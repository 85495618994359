define("docyet-client/authenticators/devise", ["exports", "ember-simple-auth/authenticators/devise", "jquery", "docyet-client/config/environment"], function (_exports, _devise, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // const { RSVP, isEmpty, run } = Ember;

  /**
   * Logic to Login and Logout from system using Devise as
   * authentication method
   */
  // https://www.adibsaad.com/2015/11/29/token-authentication-with-ember-21
  var _default = _devise.default.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    tokenEndpoint: "".concat(_environment.default.host, "/api/v1/user_auth/sign_in"),
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.accessToken) && !Ember.isEmpty(data.expiry) && !Ember.isEmpty(data.tokenType) && !Ember.isEmpty(data.uid) && !Ember.isEmpty(data.client)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },

    /**
     * Authentication logic: requests token to API
     * and stores credentials in session data
     * @param creds
     * @return {RSVP.Promise}
     */
    authenticate: function authenticate(creds) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var data = JSON.stringify({
          email: creds.identification,
          password: creds.password
        });
        var requestOptions = {
          url: _this.tokenEndpoint,
          type: 'POST',
          data: data,
          contentType: 'application/json',
          dataType: 'json'
        };

        _jquery.default.ajax(requestOptions).done(function (response, status, xhr) {
          //save the five headers needed to send to devise-token-auth
          //when making an authorized API call
          var result = {
            accessToken: xhr.getResponseHeader('access-token'),
            expiry: xhr.getResponseHeader('expiry'),
            tokenType: xhr.getResponseHeader('token-type'),
            uid: xhr.getResponseHeader('uid'),
            client: xhr.getResponseHeader('client')
          };
          Ember.run(null, resolve, result);
        }).catch(function (error) {
          Ember.run(null, reject, error.responseJSON || error.responseText);
        });
      });
    },

    /**
     * Session Deactivation logic: requests API to disable token,
     * ending user session
     * @return {*}
     */
    invalidate: function invalidate() {
      var _this2 = this;

      // For HAZ we need to remove the ID-token fragment
      // otherwise the login will be triggered again right after having logged out
      history.replaceState(null, null, ' ');
      return this.ajax.del("".concat(_environment.default.host, "/api/v1/user_auth/sign_out")).catch(function () {
        _this2.router.transitionTo(_environment.default.liveLoginEnabled ? 'live-login' : 'login');
      });
    }
  });

  _exports.default = _default;
});