define("docyet-client/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute('index'), this.toRoute('login'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('index'), this.toRoute('live-login'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('live-login'), this.toRoute('login'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('index'), this.toRoute('feature-discovery'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('chatbot'), this.toRoute('configuration'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('index'), this.toRoute('configuration'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('chatbot'), this.toRoute('help'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('index'), this.toRoute('help'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('chatbot'), this.toRoute('history'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('index'), this.toRoute('history'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('chatbot'), this.toRoute('profile'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('chatbot'), this.toRoute('menu'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('profile'), this.toRoute('menu'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('index'), this.toRoute('profile'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('signup'), this.toRoute('feature-discovery'), this.use('toLeft'), this.reverse('toLeft'));
    this.transition(this.fromRoute('login'), this.toRoute('feature-discovery'), this.use('toLeft'), this.reverse('toLeft'));
    this.transition(this.fromRoute('live-login'), this.toRoute('feature-discovery'), this.use('toLeft'), this.reverse('toLeft'));
    this.transition(this.fromRoute('login'), this.toRoute('reset-password'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('live-login'), this.toRoute('reset-password'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('login'), this.toRoute('signup'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('live-login'), this.toRoute('signup'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('feature-discovery'), this.toRoute('chatbot'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('login'), this.toRoute('configuration'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('live-login'), this.toRoute('configuration'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('menu'), this.toRoute('legal'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('menu'), this.toRoute('history'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('login'), this.toRoute('chatbot'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('login'), this.toRoute('menu'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('live-login'), this.toRoute('menu'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('live-login'), this.toRoute('chatbot'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('profile'), this.toRoute('configuration'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('configuration'), this.toRoute('profile'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('menu'), this.toRoute('help'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('help'), this.toRoute('menu'), this.use('toRight'), this.reverse('toLeft'));
  }
});