define("docyet-client/adapters/application", ["exports", "ember-inflector", "active-model-adapter", "docyet-client/config/environment"], function (_exports, _emberInflector, _activeModelAdapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Configures default HTTP requests made by Ember Store
   * to point to our API endpoint.
   *
   * More information about Application Adapter configuration:
   * ----------------------------------------------------------
   * https://gist.github.com/stephencarr/019a4ae51b4b643959120fdc92efe985
   */
  var _default = _activeModelAdapter.default.extend({
    session: Ember.inject.service(),
    host: "".concat(_environment.default.host),
    namespace: 'api/v1',

    /**
     * This function is called on each Ember Store request to configure
     * headers with authentication
     * @param xhr
     */
    headers: Ember.computed('session.data.authenticated', function () {
      var headers = {};

      var _this$get = this.get('session.data.authenticated'),
          accessToken = _this$get.accessToken,
          client = _this$get.client,
          uid = _this$get.uid;

      if (this.session.isAuthenticated) {
        headers['access-token'] = accessToken;
        headers['client'] = client;
        headers['uid'] = uid;
      }

      return headers;
    }),

    /**
     * This function controls that on each HTTP there are no new tokens.
     * If a new token is returned current session is updated with Token
     * data.
     * @param status
     * @param headers
     */
    handleResponse: function handleResponse(status, headers) {
      if (!headers['access-token']) return this._super.apply(this, arguments);
      var newSession = this.get('session.data');
      newSession.authenticated.accessToken = headers['access-token'];
      newSession.authenticated.expiry = headers['expiry'];
      newSession.authenticated.tokenType = headers['token-type'];
      newSession.authenticated.uid = headers['uid'];
      newSession.authenticated.client = headers['client'];
      this.session.get('store').persist(newSession);

      if (status = 401) {
        this.session.invalidate();
      }

      return this._super.apply(this, arguments);
    },
    pathForType: function pathForType(type) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(type));
    }
  });

  _exports.default = _default;
});