define("docyet-client/templates/live-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FK//lHu0",
    "block": "{\"symbols\":[],\"statements\":[[8,\"base-live-login/login\",[],[[\"@login\",\"@tokenLogin\"],[[30,[36,0],[[32,0],\"login\"],null],[30,[36,0],[[32,0],\"tokenLogin\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "docyet-client/templates/live-login.hbs"
  });

  _exports.default = _default;
});