define("docyet-client/components/base-components/bouncing-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="docyet-bounce1"></div>
  <div class="docyet-bounce2"></div>
  <div class="docyet-bounce3"></div>
  */
  {
    "id": "qfk2QgbR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"docyet-bounce1\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"docyet-bounce2\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"docyet-bounce3\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "docyet-client/components/base-components/bouncing-spinner.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});